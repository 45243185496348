import { CardContentBase } from '@components/card-content-base'
import * as React from 'react'
import { SubscriptionDetails } from '@modules/subscription/models'
import { CircleCheck } from '@components/circle-check'
import { declination } from '@helpers/declination'
import { SubscriptionDetailsContentOptions } from '@modules/subscription/details/subscription-details-content-options'

interface Props {
  staysCount: number
  subscriptionDetails: SubscriptionDetails
  accommodationTypeName: string | undefined
  maxGuests: number
  onScrollToPayment: () => void
  expiration: string
}

export const SubscriptionDetailsContent = ({
  subscriptionDetails,
  accommodationTypeName,
  staysCount,
  maxGuests,
  onScrollToPayment,
  expiration,
}: Props): JSX.Element => {
  const profits = [
    `${staysCount} ${declination.stayDays(staysCount)} ${
      subscriptionDetails.defaults_days + 1
    }-dniowych dla ${maxGuests} osób`,
    <span key="prices">
      ceny pobytów tańsze <strong>nawet o 80%</strong>
    </span>,
    'możliwość korzystania z atrakcji dostępnych w resortach',
    <span key="maturity">
      każdy pakiet możesz wykorzystać <strong>{expiration}</strong>
    </span>,
  ]

  return (
    <div>
      <CardContentBase
        title={`Potwierdzenie zakupu pakietu ${staysCount} ${declination.stayDays(
          staysCount,
        )} w ${accommodationTypeName}`}
        client={subscriptionDetails.client}
        seller={subscriptionDetails.seller}
        created={subscriptionDetails.created || ''}
        isProductPaid={subscriptionDetails.is_paid}
        onScrollToPayment={onScrollToPayment}
        productInvoice={subscriptionDetails}
      />
      <hr className="mx-n5" />
      <div>
        <strong className="text-secondary font-xxl">Co otrzymujesz kupując pakiet pobytów?</strong>
        <strong className="text-darker-gray d-block mt-1">
          Kupując pakiet {staysCount} {declination.stayDays(staysCount)} otrzymasz:
        </strong>
        <div className="mt-3 row">
          {profits.map((profit, index) => (
            <CircleCheck key={index} className="col-12 col-md-4 mb-2">
              <span className="font-md lh-initial col-12 col-md-8">
                <span className="text-muted">{profit}</span>
              </span>
            </CircleCheck>
          ))}
        </div>
        {!!subscriptionDetails.options.length && (
          <SubscriptionDetailsContentOptions
            options={subscriptionDetails.options}
            status={subscriptionDetails.status}
          />
        )}
      </div>
    </div>
  )
}
