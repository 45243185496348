import { AccommodationType } from '@models/app-data'
import Decimal from 'decimal.js'
import { formatDistanceStrict } from 'date-fns'
import { parseISODate } from '@helpers/date-formatter'
import { pl } from 'date-fns/locale'
import { SubscriptionDetails } from '@modules/subscription/models'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selector'

interface Response {
  staysCount: number
  accommodationTypeName: string | undefined
  accommodationType: AccommodationType | undefined
  expirationPrefix: string
  expiration: string
}

export const useSubscriptionProductData = (subscriptionDetails: SubscriptionDetails | undefined): Response => {
  const appData = useSelector(selectAppData)

  const accommodationType = appData.accommodation_types.find(
    (accommodationType: AccommodationType) => subscriptionDetails?.accommodation_type === accommodationType.id,
  )

  const staysCount = new Decimal(subscriptionDetails?.local_mountains || 0)
    .plus(subscriptionDetails?.local_sea || 0)
    .plus(subscriptionDetails?.local_universal || 0)
    .toNumber()

  const accommodationTypeName = accommodationType?.short_name_language_miejscownik.split(',')[0]

  const expirationPrefix = subscriptionDetails?.status === 'initial' ? 'przez' : 'jeszcze przez'

  const expiration = subscriptionDetails
    ? subscriptionDetails.status === 'initial'
      ? formatDistanceStrict(
          parseISODate(subscriptionDetails.expire_after),
          parseISODate(subscriptionDetails.created),
          { locale: pl },
        )
      : formatDistanceStrict(parseISODate(subscriptionDetails.expire_after), new Date(), { locale: pl })
    : ''

  return {
    staysCount,
    accommodationTypeName,
    accommodationType,
    expirationPrefix,
    expiration,
  }
}
