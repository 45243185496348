import * as React from 'react'
import * as clsx from 'clsx'
import { useRules } from '@hooks/use-rules'

interface Props {
  className?: string
}

export const GeneralTerms = ({ className }: Props): JSX.Element => {
  const { getRules } = useRules()

  const rules = getRules(['information_clause'])

  return (
    <div className={clsx('py-3 font-md app-footer__terms-wrapper', className)}>
      <div className="container app-footer__terms-wrapper__content">
        {rules.map(term => (
          <a
            className="text-darker-gray text-decoration-none text-center col-12 col-sm-auto mb-2 mb-sm-0"
            href={term?.url}
            key={term?.keyword}
            target="_blank"
          >
            {term?.name}
          </a>
        ))}
      </div>
    </div>
  )
}
