import * as React from 'react'
import * as clsx from 'clsx'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { StaticRules } from '@helpers/rules'
import { ExpandableText } from '@components/controls/expandable-text'

export const MARKETING_RULES_KEYWORDS = ['email_rules', 'sms_rules', 'phone_call_rules']

interface Props {
  fullWidth?: boolean
  allSelectionClassName?: string
}

export const MarketingAgreementSection = ({
  fullWidth,
  allSelectionClassName = 'checkbox--small font-size-sm align-items-start',
}: Props): React.ReactNode => {
  const { control, setValue } = useFormContext()

  const marketingRules = useWatch({ control, name: MARKETING_RULES_KEYWORDS })
  const selectedMarketingRules = marketingRules.filter(Boolean)

  const isAnyMarketingRuleSelected =
    selectedMarketingRules.length > 0 && selectedMarketingRules.length < MARKETING_RULES_KEYWORDS.length

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked

    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
  }

  React.useEffect(() => {
    const isEachSelected = marketingRules.every(Boolean)
    setValue('allMarketingRulesSelected', isEachSelected)
  }, [marketingRules])

  return (
    <>
      <Checkbox
        className={allSelectionClassName}
        name="allMarketingRulesSelected"
        id="allMarketingRulesSelected"
        onChange={handleAllSelection}
        label={
          <div className="mt-1">
            <div className="mb-1">Zgoda na przetwarzanie danych w celach marketingowych</div>
            <div className={clsx('d-block mt-1 col-12 text-pre-line font-md lh-initial', { 'col-md-7': !fullWidth })}>
              <ExpandableText text={StaticRules.marketing_rules} initialHeight={12} />
            </div>
          </div>
        }
        indeterminate={isAnyMarketingRuleSelected}
      />

      <div className="mt-1" style={{ marginLeft: 29 }}>
        <p className="text-darker-gray font-size-sm mb-1 col-lg-7">
          {StaticRules.marketing_rules_communication_channel}
        </p>

        <Checkbox
          name="phone_call_rules"
          id="phone_call_rules"
          label={<div className={clsx('mt-1 col-12', { 'col-sm-8': !fullWidth })}>telefonu</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start"
        />

        <div className="mt-2 mb-1 pl-2 text-darker-gray">środków komunikacji elektronicznej, w szczególności:</div>

        <Checkbox
          name="email_rules"
          id="email_rules"
          label={<div className={clsx('mt-1 col-12', { 'col-sm-9': !fullWidth })}> wiadomości email</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start"
        />
        <Checkbox
          name="sms_rules"
          id="sms_rules"
          label={<div className={clsx('mt-1 col-12', { 'col-sm-8': !fullWidth })}>wiadomości SMS</div>}
          className="checkbox--small font-size-sm fw-normal font-size-sm align-items-start"
        />
      </div>
    </>
  )
}
